@import url('https://fonts.googleapis.com/css?family=Cormorant+Garamond');
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.cenetered{
  display:flex;
  justify-content:center;
  align-item:center
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
#root{
  font-family: 'Cormorant Garamond';
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.link{
  width: 1vh;
  height: 1vh;
}
.linkSmall{
  width: .75vh;
  height: .75vh;
  margin-bottom: .5vh;
}

.viewport100{
  height:100vh;
}

.code{
  background-image: url("./img/code.png");
  background-size: cover;
  background-position: center;
  color:white;
  background-repeat:   no-repeat;
  text-align: center;
  font-size: xx-large;
}
.viewport{
  height: 70vh;
}
#propic{
  width: 15vh;
  height: 20vh;
  border-radius: 1vh;;
}
.ucla{
  width: 10vh;
  height: 10vh;
}
.cont{
  paddingLeft: 2vh;
  paddingRight: 2vh;
}
.contactPhotos{
  width: 8vh;
  height: 8vh;
  padding: 1vh 1vh 1vh 1vh;
}
.contactPhotos2{
  width: 6.5vh;
  height: 6.5vh;
  padding: 1vh 1vh 1vh 1vh;
}
.centered{
  text-align: center;
}
.nb{
  padding-bottom: 5vh;
}
.nb2{
  padding-top: 2vh;
}

.button {
  border-radius: .5vh;
  color: #FFFFFF;
  text-align: center;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.button span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -1vh;
  transition: 0.5s;
}

.button:hover span {
  padding-right: .5vh;
}

.button:hover span:after {
  opacity: 1;
  right: -.5vh;
}

.button2 {
  border-radius: .5vh;
  text-align: center;
  transition: all 0.5s;
  cursor: pointer;
  background-color: #DCDCDC !important;
  color: black;
}

.button2 span {
  cursor: pointer;
  position: relative;
  transition: 0.5s;
  border-color: #ccc;
}

.button2 span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: -.25vh;
  right: -1vh;
  transition: 0.5s;

}

.button2:hover span {
  padding-right: .5vh;
}

.button2:hover span:after {
  opacity: 1;
  right: -.5vh;
}
